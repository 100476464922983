(function ($) {
	$(window).load(function () {
		// owl-carousel
		$(".owl-carousel:not(.slider)").owlCarousel({
			items: 1,
			loop: true,

			// autoplay
			autoplay: true,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// animation
			animateIn: "fadeIn",
			animateOut: "fadeOut",

			// navigation
			nav: false,
			navText: [
				'<i class="fas fa-chevron-left"></i>',
				'<i class="fas fa-chevron-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: false,
		});

		// slider
		$(".slider:not(.blog-location-overview)").owlCarousel({
			items: 1,
			margin: 16,
			loop: ( $(window).width() < 578 ), // only on mobile

			// autoplay
			autoplay: false,
			autoplayTimeout: 7000,

			// speed
			smartSpeed: 500,

			// stage padding
			// this code calculate the space around the container
			stagePadding:
				($(window).innerWidth() - $(".container").first().innerWidth()) / 2,

			// center
			// center: true,

			// navigation
			nav: true,
			navText: [
				'<i class="fal fa-arrow-circle-left"></i>',
				'<i class="fal fa-arrow-circle-right"></i>',
			],
			dots: false,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				},
				768: {
					items: 2,
				},
				992: {
					items: 2,
				},
				1200: {
					items: 3,
				},
			},
		});

		// blog-location-overview
		$(".blog-location-overview").owlCarousel({
			items: 1,
			margin: 16,
			loop: ( $(window).width() < 578 ), // only on mobile

			// autoplay
			autoplay: false,

			// speed
			smartSpeed: 500,

			// stage padding
			// this code calculate the space around the container
			stagePadding:
				($(window).innerWidth() - $(".container").first().innerWidth()) / 2,

			// center
			// center: true,

			// navigation
			nav: true,
			navText: [
				'<i class="fal fa-arrow-circle-left"></i>',
				'<i class="fal fa-arrow-circle-right"></i>',
			],
			dots: true,

			// drag
			mouseDrag: true,

			// responsive
			responsive: {
				0: {
					items: 1,
				},
				576: {
					items: 2,
				}
			},
		});

		// Set Sliders Navigation position
		if ( $(window).width() > 578 ) {
			$('.owl-carousel.slider .owl-nav').css({
				right: ($(window).innerWidth() - $(".container").first().innerWidth()) / 2,
			});
		}

		// form-sent
		setTimeout(() => {
			var locationSearch = "" + document.location.search;
			if (
				(-1 === locationSearch.indexOf("form[sent]") &&
					-1 === decodeURIComponent(locationSearch).indexOf("form[sent]")) ||
				-1 === document.location.hash.indexOf("#form")
			) {
				return;
			}

			var $headerHeight = $(".header");
			var $message = $(".form-success-message");

			if (!$headerHeight.length || !$message.length) {
				return;
			}

			var currentScrollTop = $("html").scrollTop();
			var newScrollTop =
				$message.offset().top - $headerHeight.outerHeight() - 30;
			if (newScrollTop >= currentScrollTop) {
				return;
			}

			$("html, body").animate({
				scrollTop: $message.offset().top - $headerHeight.outerHeight() - 30,
			});
		}, 500);
	});

	$(document).ready(function () {
		// sticky
		$(window).scroll(function () {
			var $el = $("body").add(".header");

			if (!$("body").hasClass("location-overview")) {
				if ($(window).scrollTop() > 0) {
					$el.addClass("sticky");
				} else {
					$el.removeClass("sticky");
				}
			}
		});

		// fancybox
		$("a[data-fancybox]").fancybox({
			buttons: [
				//"zoom",
				//"share",
				//"slideShow",
				//"fullScreen",
				//"download",
				"thumbs",
				"close",
			],
		});

		// scroll-to-content
		$(".js-3w-scroll-to-content").on("click", function (event) {
			event.preventDefault();
			var identifier = $(this).attr("data-target");
			var $element = $(identifier);

			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top }, 500);
			}
		});

		$('a[href^="#"]').on("click", function (e) {
			e.preventDefault();
			const identifier = $(this).attr("href");
			var $element = $(identifier);
			if ($element) {
				$("html, body").animate({ scrollTop: $element.offset().top - 140 }, 300);
			}
		});

		// wait for the last carousle initialized
		$('.owl-carousel').last().on('initialized.owl.carousel', function(e) {
		
			// sticky location navbar
			const $stickyNavbar = $(".nav-section");
			const $stickyStopper = $(".footer");
			const $stickyHeader = $(".header-main");
			if (!!$stickyNavbar.offset()) {
				// make sure ".sticky" element exists

				// add active class to nav item on scrolling to same section
				const $navLinks = $('ul.location-nav li a'); // nav links
				const $sections = [];
				$navLinks.each(function() {
					$sections.push($(this).attr('href')); // section ids
				});

				const generalNavbarHeight = $stickyNavbar.height();
				const stickyTop = $stickyNavbar.offset().top;
				const stickyStopperPosition = $stickyStopper.offset().top;
				
				console.log(stickyStopperPosition)

				$(window).scroll(function () {
					const stickOffset = $stickyHeader.height() + 20;
					const stopPoint =
						stickyStopperPosition - generalNavbarHeight - stickOffset;
					const diff = stickyStopperPosition - generalNavbarHeight;
					// scroll event
					const windowTop = $(window).scrollTop(); // returns number

					if (stopPoint < windowTop) {
						// on the footer
						$stickyNavbar.css({ position: 'absolute', top: diff });
					} else if (stickyTop < windowTop + stickOffset) {
						// on scrolling
						$stickyNavbar.css({ position: 'fixed', top: stickOffset });
						$stickyNavbar.addClass('sticky-navbar');
					} else {
						// default
						$stickyNavbar.css({ position: 'relative', top: "initial" });
						$stickyNavbar.removeClass('sticky-navbar');
					}

					$.each($sections, function(index, value){
						const $section = $(value);
						// return;
						const $sectionTop = $section.offset().top;
						const changePoint =  $sectionTop - (generalNavbarHeight + stickOffset + 100);
						if ($(window).scrollTop() >= changePoint) {
							// current = section.getAttribute("id"); 
							$('ul.location-nav li a[href="'+value+'"]')
								.parent()
								.addClass('active')
								.siblings()
								.removeClass('active');
						}
					});
				});
			}

			if ( $('div.location-nav').length > 0 ) {
				$('div.location-nav').addClass('scrollable to-left');
			}
			$('ul.location-nav').on('scroll', function() {
				const navItems = $(this).children();
				const navWidth = $(this).width();
				let totalWidth = 0;
				navItems.each(function(i) {
					totalWidth += parseInt(Math.ceil($(this).outerWidth(true)), 10);
				});
				const scrollWidth = totalWidth;

				// add classes
				$(this).parent().addClass('scrollable');
				if ( Math.ceil( $(this).scrollLeft() ) === 0 ) {
					$(this).parent().addClass('to-left');
					$(this).parent().removeClass('to-right');
				} else {
					$(this).parent().addClass('to-right to-left');
				}

				if ( Math.ceil( ($(this).scrollLeft() + navWidth) + 1 ) >= scrollWidth ) {
					$(this).parent().removeClass('to-left');
					$(this).parent().addClass('to-right');
				}
			});
		});


		// toggle Faciliteiten & omgeving on mobile
		if ( $(window).width() < 578 ) {
			$('#f-and-o h2').on('click', function(e) {
				$(this).next('.row').toggle();
				$(this).toggleClass('opend');

			});
		}

		// toggle container in search book page
		$(document).on('click', '.toggle-list-item', function(e) {
			if ( $(this).hasClass('active') ) {
				$('.location-overview .bundle-overview-section .container-one-column').removeClass('container').addClass('container-fluid');
			} else {
				$('.location-overview .bundle-overview-section .container-one-column').removeClass('container-fluid').addClass('container');
			}
		});
	});
})(jQuery);